<template>
	<b-card class="mb-0" no-body>
		<div>
			<h4 class="text-center mb-2">{{ title }}</h4>
		</div>
		<!-- chart -->
		<base-doughnut-chart :height="height" :data="chartData.data" :options="chartData.options" />
	</b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import BaseDoughnutChart from './base-charts/BaseDoughnutChart';

export default {
	components: {
		BaseDoughnutChart,
		BCard
	},

	props: {
		title: {
			type: String,
			default: ''
		},

		chartData: {
			type: Object,
			required: true
		},

		height: {
			type: [Number, String],
			default: 400
		}
	}
};
</script>
